var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"historyChargingInfo"},[_c('commonNavBar',{attrs:{"title":`${_vm.yearDate(_vm.$route.query.year)}计费详情`}}),_c('van-overlay',{attrs:{"show":_vm.loadingShow}},[_c('van-loading',{attrs:{"text-color":"#0094ff","color":"#0094ff","vertical":""}},[_vm._v("加载中...")])],1),_c('div',{directives:[{name:"watermark",rawName:"v-watermark"}],staticClass:"info"},[_c('div',{ref:"scrollBox",staticClass:"scrollBox",on:{"scroll":_vm.onScroll}},[_c('van-collapse',{attrs:{"accordion":""},model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},[_c('van-collapse-item',{attrs:{"title":"基本信息","name":"1"}},[_c('van-cell',{attrs:{"center":"","title":"施工协议号:"},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.standardInfo.code && _vm.standardInfo.agreementId)?_c('div',{staticStyle:{"color":"#1990ff"},on:{"click":function($event){return _vm.$router.push({
                  name: 'constructionAddressInfo',
                  params: {
                    id: _vm.standardInfo.agreementId,
                    Refresh: true
                  }
                })}}},[_vm._v(" "+_vm._s(_vm.standardInfo.code)+" ")]):_c('div',[_vm._v("--")])]},proxy:true}])}),_c('van-cell',{attrs:{"center":"","title":"计费单位:","value":_vm.standardInfo.chargingCompanyName || '--'}}),_c('van-cell',{attrs:{"center":"","title":"计费年份:","value":_vm._f("yearFormat")(_vm.standardInfo.chargingYear)}}),_c('userJump',{attrs:{"title":"更新人:","userList":_vm.standardInfo.updateBy}}),_c('van-cell',{attrs:{"center":"","title":"更新日期:","value":_vm._f("openYmdFormat")(_vm.standardInfo.updateDatetime)}})],1),_c('van-collapse-item',{attrs:{"title":"合同计费","name":"2"}},[_c('van-cell',{attrs:{"center":"","title":"计费方式:","value":_vm.standardInfo?.charging?.chargingUnit || '--'}}),_c('van-cell',{attrs:{"center":"","title":"应付服务费:","value":_vm._f("moneyFormat")(_vm.standardInfo?.charging?.yearServerCost)}}),_c('van-cell',{attrs:{"center":"","title":"减免总额:","value":_vm._f("moneyFormat")(_vm.standardInfo?.charging?.allPreferential)}}),_c('van-cell',{attrs:{"center":"","title":"实付服务费(含税):","value":_vm._f("moneyFormat")(_vm.standardInfo?.charging?.allMoneyHasTax)}}),_c('van-cell',{attrs:{"center":"","title":"计费明细:"}},[(_vm.standardInfo?.charging?.chargingInfo)?_c('span',{staticStyle:{"color":"#1990ff"},on:{"click":function($event){return _vm.chargingInfoHandler(_vm.standardInfo.id)}}},[_vm._v(_vm._s(_vm.standardInfo?.charging?.chargingInfo)+"条")]):_c('span',[_vm._v("--")])])],1),_c('van-collapse-item',{attrs:{"title":"其他信息","name":"3"}},[_c('van-cell',{attrs:{"center":"","title":"补充说明:"},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.standardInfo.other)?_c('span',{staticStyle:{"color":"#1990ff"},on:{"click":function($event){return _vm.clickDialogHandler('补充说明', _vm.standardInfo.other)}}},[_vm._v("点击查看")]):_c('span',[_vm._v("--")])]},proxy:true}])})],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }